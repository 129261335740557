import React, {useEffect, useState} from "react";
import {Sidebar} from "primereact/sidebar";
import cn from "classnames";
import {Button} from "primereact/button";
import "./SideBar.scss";
import {sources} from "../../../../helpers"
import {Checkbox} from "primereact/checkbox";
import {inject, observer} from "mobx-react";
import {TopForm} from "../../../../stores/forms/topForm.store";

const AdPlatformSideBar = ({geoStore, visibleRight, setVisibleRight, fields, code}) => {

    const currentPlace = geoStore.getCurrentSetup(code);
    const checkedElement = [];

    const onChecked = (result) => {
        checkedElement.push(result);
    }

    const save = () => {
        checkedElement.map(element => {
            if(element.checked) {
                const foo = fields.map(field => {
                    return {
                        ...field,
                        ...{'code':`${field.code}@${element.value}`}
                    }
                })

                const item = {
                    active: !currentPlace.blocks.length,
                    source: element.value,
                    name: element.name,
                    fields: foo
                }

                const topForm = new TopForm(item);
                geoStore.pushSetupPlaceValue(code, topForm.getPlainObject());
            }
            else {
                geoStore.removeSetupPlaceItem(code, element.value)
            }
        })
        setVisibleRight(false)
    }

    return (
        <>
            <Sidebar
                visible={visibleRight}
                position="right"
                onHide={() => setVisibleRight(false)}
                className={cn('side-bar')}
                blockScroll={true}
                icons={() => (
                    <button
                        className={cn('p-link', 'close-text')}
                        onClick={() => setVisibleRight(false)}
                    >
                        Закрыть
                    </button>
                )}
            >
                <div className={cn('side-bar__title')}>
                    <h3>Выберите площадку</h3>
                </div>

                <div className={cn('side-bar__body', 'main-form')}>
                    <ul className={cn("sources-list")}>
                        {sources.map((item, key) => <SourceItem
                            key={key}
                            item={item}
                            onChecked={onChecked}
                            adPlatforms={currentPlace.blocks}/>)}
                    </ul>

                    <div className={cn('btn-form')}>
                        <Button
                            label="Применить"
                            onClick={save}
                        />
                    </div>
                </div>
            </Sidebar>
        </>
    )
}


const SourceItem = ({onChecked, item, adPlatforms}) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (!!adPlatforms.find(ad => ad.source === item.value)) {
            setChecked(true)
        }
    }, [])

    return (
        <label htmlFor={item.value} className="p-checkbox-label"><li>
            <Checkbox
                inputId={item.value}
                value={item.value}
                checked={checked}
                onChange={() => {
                    onChecked(
                        {
                            checked: !checked,
                            value: item.value,
                            name: item.name,
                        }
                    )
                    setChecked(!checked)
                }}
            ></Checkbox>
            <img src={item.ico} alt="" width={30} height={30} title={item.name}/>
            {item.name}
        </li></label>
    )

}
export default inject("geoStore")(observer(AdPlatformSideBar));