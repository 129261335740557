import React, { useEffect, useState } from "react";
import cn from "classnames";
import _ from "underscore";
import { inject, observer } from "mobx-react";
import geoCategoryService from "../../../../../services/geo/geo-category";
import { AutoComplete } from "antd";
import {reaction, toJS} from "mobx";

const LegalInfoCategorySingle = inject('geoStore')(observer((props) => {
    const { geoStore, name } = props;

// получили данные из основной category
    const [valueList, setValueList] = useState(props.geoStore.form[name].value);

    const saveCategoryList = (object) => {

        //сохраняем в основной объект category
        geoStore.form['category'].value.push({
            id: object.id+'',
            title: object.title,
            primary: geoStore.form['category'].value.length === 0,
            children: [],
        })

        if(object.children) {
            object.children.map(child => {
                geoStore.saveValueInSetup('legalInfoSetup', child.type.code, {id: child.id, title: child.title})
            })
        }
    }

    const singleProps = {
        ...props,
        ...{
            saveCategoryList,
            geoStore,
            valueList,
        },
    }

    return (
        <LegalInfoCategory {...singleProps} />
    )
}));

const LegalInfoCategoryMulti = inject('geoStore')(observer((props) => {
    const { geoStore, code, source, name } = props;

    // получили данные из setupPlace['legalInfoSetup']
    const [parentCategoryList, setParentCategoryList] = useState(geoStore.getSetupFieldValueFromStore(code, source, name))

/*
    reaction(
        () => JSON.stringify(toJS(props.geoStore.form.setupPlace[1])),
        (result) => {
            setParentCategoryList(geoStore.getSetupFieldValueFromStore(code, source, name))
        }
    );
*/


    useEffect(()=>{
        //console.log('LegalInfoCategoryMulti parentCategoryList', toJS(parentCategoryList))
    }, [parentCategoryList])


    const saveCategoryList = (object) => {
        // сохраняем в расширенный объект setupPlace['legalInfoSetup']
        geoStore.saveValueInSetup(code, source, object)
    }

    const multiProps = {
        ...props,
        ...{
            saveCategoryList,
            geoStore,
            parentCategoryList
        },
    }
    return (
        /*<>{parentCategoryList !== null && <LegalInfoCategory {...multiProps} />}</>*/
        <>
            <LegalInfoCategory {...multiProps} />
        </>
    )
}))

const LegalInfoCategory = ({ name = '', saveCategoryList, parentCategoryList, geoStore, valueList, source } ) => {

    const [categoryList, setCategoryList] = useState([]);
    const [options, setOptions] = useState([]);
    const [fieldName, setFieldName] = useState('');
    const [value, setValue] = useState('');

// это временно
    reaction(
        () => JSON.stringify(toJS(geoStore.form.setupPlace[1])),
        (result) => {

            const blocks =  JSON.parse(result).blocks;
            const block = blocks.find(b => b.source === source);
            if(block !== undefined) {
                setCategoryList(block.fields[0].fieldValue)
            }
        }
    );

    useEffect(()=>{
        setFieldName(name)

        if(!!parentCategoryList) {
            setCategoryList(parentCategoryList)
        }
        else {
            setCategoryList(valueList)
        }
    }, [])

    const getRubricsAdplatform = _.debounce(
        (value, code) => {
            if (value.length >= 1 || value.length === 0) {
                geoCategoryService.getRubricsAdplatform(value, code).then(result => {
                    setOptions(result.items.map(res => {
                        return { value: res.title, label: res.title, object: {id: res.id, title: res.title, code: res.type.code}}
                    }))
                })
            }
        },
        800
    );

    const getRubricsCompound = _.debounce(
        (value) => {
            if (value.length >= 1 || value.length === 0) {
                geoCategoryService.getRubricsCompound(value).then(result => {
                    setOptions(result.items.map(res => {
                        return { value: res.title, label: res.title, object: res }
                    }))
                })
            }
        },
        800
    );

    const onDeleteCategory = (key) => {
        let deleteItem;
        let copy = [
            ...[],
            ...categoryList
        ];

        //console.log('categoryList', categoryList)

        if (key > -1) {
            deleteItem = copy.splice(key, 1);
        }

        geoStore.removeDataFromField(deleteItem[0], source)
        setCategoryList(copy);
    }

    const handleKeyPress = (ev) => {
        setValue(ev.target.value)
    };

    const onSelect = (value, result) => {
        const list = [
            ...categoryList,
            ...[result.object]
        ];

        saveCategoryList(result.object)

        setCategoryList(list);
        setValue('')
        setOptions([])
    };

    const handleSearch = (value) => {
        if(source) {
            getRubricsAdplatform(value, source)
        }
        else {
            getRubricsCompound(value)
        }
    };

    return (
        <>
            <div className={cn("form__row")}>
                <div className={cn('text-field', 'text-field--width-580')}>
                    {fieldName !== '' && <div className={cn("p-float-label")}>
                        <AutoComplete
                            options={options}
                            style={{
                                width: '100%'
                            }}
                            id={fieldName}
                            name={fieldName}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                            onKeyPress={handleKeyPress}
                            onChange={(value)=>{
                                setValue(value)
                            }}
                            value={value}
                            placeholder={'Добавление категорий (начните вводить)'}
                        >
                            <input type="text" className={cn('p-inputtext p-component')}/>
                        </AutoComplete>
                    </div>}
                </div>
                <div className={cn('description-field')}>
                    <p>Соответствующие категории для каталога будут подобраны автоматически</p>
                </div>
            </div>

            <div className={cn("form__row")}>
                <div className={cn('pills-field')}>
                    {categoryList?.length > 0 && categoryList.map((item, key) => {
                        return <Pill
                            key={key}
                            primary={item.primary}
                            onDelete={onDeleteCategory}
                            index={key}
                        >{item.title}</Pill>
                    })}
                </div>
            </div>
        </>
    )
};

const Pill = ({ onDelete, children, index, primary = false }) => {
    return (
        <>
            <span className={cn("pills", {"primary": primary})}>
                {children}
                <i className={cn("pi", "pi-times")}
                   onClick={() => onDelete(index)}
                ></i>
            </span>
        </>
    )
}

export {
    LegalInfoCategorySingle,
    LegalInfoCategoryMulti,
}